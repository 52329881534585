/**
 * 구글 시트로 관리되는 json 파일 이름 리스트
 * 쉽다 웹 한글 문구 및 번역 문구 각 시트를 로컬 json파일로 변경시 사용 할 이름
 * 탭 이름을 기준으로 작성
 *
 * @example
 * shared                        : shared (공통(프로덕트 공용 문구))
 * common-new                    : common-new (공용 UI(프로덕트 공통 컴포넌트))
 * sds-v1                        : V1 디자인시스템
 * sds-v2                        : V2 디자인시스템
 * sds-v3                        : V3 디자인시스템
 * auth-new                      : 회원가입/로그인
 * main-landing                  : 랜딩
 * page-company                  : page-회사소개
 * page-mypage-bid               : page-마이페이지-운송관리-수입/수출운송
 * page-mypage-bid-exportation   : page-마이페이지-운송관리-수출운송에서만 사용되는 문구
 * page-mypage-settlement        : page-마이페이지-운송관리-정산관리
 * page-mypage-po                : page-마이페이지-발주관리-발주서
 * page-mypage-partner           : page-마이페이지-업체관리
 * support-new                   : 가이드/공지사항/동영상
 * page-mypage-company-info      : page-마이페이지-관리/설정-회사정보-정보변경
 * page-mypage-company-member    : page-마이페이지-관리/설정-회사정보-멤버관리
 * page-mypage-member            : page-마이페이지-관리/설정-사용자정보-정보변경
 * page-mypage-coupon            : page-마이페이지-관리/설정-쿠폰
 * page-mypage-dashboard         : page-마이페이지-대시보드
 * components                    : components
 * containers                    : containers
 * hocs                          : hocs
 * hooks                         : hooks
 * utils                         : utils
 * queries                       : queries
 * constants                     : constants
 * states                        : states (recoil, jotai 모두)
 * page-forwarding-schedule      : page-서비스-스케줄 조회
 * page-forwarding-quote         : page-서비스-견적의뢰
 * page-mypage-open-api          : page-마이페이지-Open API
 * page-forwarding-ocean         : page-서비스-해상운송
 * page-forwarding-air           : page-서비스-항공운송
 * page-fulfillment-domestic     : page-서비스-국내 출고
 * page-fulfillment-overseas     : page-서비스-해외 출고
 * page-fulfillment-event        : page-서비스-이벤트 출고
 * page-tt                       : page-서비스-대금결제(T/T)
 * page-main                     : page-메인
 */
const newJsonFileNameList = [
  "shared",
  "common-new",
  "sds-v1",
  "sds-v2",
  "sds-v3",
  "auth-new",
  "main-landing",
  "page-company",
  "page-mypage-bid",
  "page-mypage-bid-exportation",
  "page-mypage-settlement",
  "page-mypage-po",
  "page-mypage-partner",
  "support-new",
  "page-mypage-company-info",
  "page-mypage-company-member",
  "page-mypage-member",
  "page-mypage-coupon",
  "page-mypage-dashboard",
  "components",
  "containers",
  "hocs",
  "hooks",
  "utils",
  "queries",
  "constants",
  "states",
  "page-forwarding-schedule",
  "page-forwarding-quote",
  "page-mypage-open-api",
  "page-forwarding-ocean",
  "page-forwarding-air",
  "page-fulfillment-domestic",
  "page-fulfillment-overseas",
  "page-fulfillment-event",
  "page-tt",
  "page-main",
] as const;

/**
 * 구글 시트에 없는 이전 json 파일 이름 리스트
 */
const legacyJsonFileNameList = [
  "common",
  "errors",
  "mypage",
  "privacy",
  "terms",
] as const;

const jsonFileNameList = [
  ...newJsonFileNameList,
  ...legacyJsonFileNameList,
] as const;

type JsonFileNameList = typeof jsonFileNameList[number];

export type { JsonFileNameList };

export { newJsonFileNameList, jsonFileNameList };
