// eslint-disable-next-line no-restricted-imports
import { useEffect, useLayoutEffect } from "react";

/**
 * SSR 환경에서는 useLayoutEffect가 경고를 발생시키기 때문에 해당 훅을 사용합니다.
 */
const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export default useIsomorphicLayoutEffect;
