import { TableHeadFilterOption } from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";
import { SelectFilterOptionList } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSelectFilter";

import { Incoterms } from "../../types/common/common";
import { FreightPaymentType } from "../../types/forwarding/adminBid";
import { CargoTransportType } from "../../types/forwarding/bid";
import { historyLogDetails } from "../../types/forwarding/businessManagement";

import { AllPossibleServiceType } from "../../api-interfaces/shipda-api/admin/businessManagement";

const ADMIN_OPERATION_INCOTERMS_OPTION_LIST: TableHeadFilterOption<Incoterms>[] =
  [
    {
      label: "EXW",
      value: "EXW",
    },
    {
      label: "FOB",
      value: "FOB",
    },
    {
      label: "FCA",
      value: "FCA",
    },
    {
      label: "CFR",
      value: "CFR",
    },
    {
      label: "CIF",
      value: "CIF",
    },
  ];

const ADMIN_OPERATION_PAYMENT_OPTION_LIST: TableHeadFilterOption<
  FreightPaymentType | "none"
>[] = [
  {
    label: "PP",
    value: "pp",
  },
  {
    label: "CC",
    value: "cc",
  },
  {
    label: "없음",
    value: "none",
  },
];

const ADMIN_OPERATION_CARGO_TYPE_OPTION_LIST: TableHeadFilterOption<CargoTransportType>[] =
  [
    {
      label: "FCL",
      value: "FCL",
    },
    {
      label: "LCL",
      value: "LCL",
    },
    {
      label: "AIR",
      value: "AIR",
    },
  ];

const ADMIN_OPERATION_SERVICE_TYPE_OPTION_LIST: TableHeadFilterOption<AllPossibleServiceType>[] =
  [
    {
      label: "Door-Door",
      value: "door-door",
    },

    {
      label: "Door-CY",
      value: "door-cy",
    },
    {
      label: "CY-Door",
      value: "cy-door",
    },
    {
      label: "CY-CY",
      value: "cy-cy",
    },

    {
      label: "Door-CFS",
      value: "door-cfs",
    },
    {
      label: "CFS-Door",
      value: "cfs-door",
    },
    {
      label: "CFS-CFS",
      value: "cfs-cfs",
    },

    {
      label: "Door-WHS",
      value: "door-whs",
    },

    {
      label: "WHS-Door",
      value: "whs-door",
    },
    {
      label: "WHS-WHS",
      value: "whs-whs",
    },
  ];

const ADMIN_OPERATION_CLOSING_OPTION_LIST: TableHeadFilterOption<boolean>[] = [
  {
    label: "발행",
    value: true,
  },
  {
    label: "미발행",
    value: false,
  },
];

const ADMIN_BUSINESS_MANAGEMENT_NO_PROCEED_REASON_OPTION_LIST: SelectFilterOptionList<historyLogDetails>[] =
  [
    {
      label: "견적 검토 중",
      value: "underReview",
    },
    {
      label: "진행 계획이나 화물 준비 중",
      value: "inPrepared",
    },
    {
      label: "수입 계획 미확정",
      value: "unconfirmed",
    },
    {
      label: "추후 진행 시 재검토",
      value: "reviewLater",
    },
    {
      label: "가격 경쟁력 부족",
      value: "price",
    },
    {
      label: "부재중",
      value: "missed",
    },
    {
      label: "진행 예정",
      value: "scheduleProceed",
    },
    {
      label: "기존 포워더 사용",
      value: "competitors",
    },
    {
      label: "수입조건 변경",
      value: "changeConditions",
    },
    {
      label: "다른 의뢰 번호로 수주 완료",
      value: "anotherBid",
    },
    {
      label: "연락 두절",
      value: "lostContact",
    },
    {
      label: "수입계획 취소",
      value: "cancelPlan",
    },
    {
      label: "진행물량 없음",
      value: "noProgress",
    },
    {
      label: "새로운 견적 의뢰",
      value: "changeBidProceed",
    },
    {
      label: "단순 운임 체크",
      value: "fareCheck",
    },
  ];

export {
  ADMIN_OPERATION_INCOTERMS_OPTION_LIST,
  ADMIN_OPERATION_PAYMENT_OPTION_LIST,
  ADMIN_OPERATION_CARGO_TYPE_OPTION_LIST,
  ADMIN_OPERATION_SERVICE_TYPE_OPTION_LIST,
  ADMIN_OPERATION_CLOSING_OPTION_LIST,
  ADMIN_BUSINESS_MANAGEMENT_NO_PROCEED_REASON_OPTION_LIST,
};
