function getPolylineCenter(polylineOptions: google.maps.PolylineOptions) {
  const path = polylineOptions.path;

  if (!path) {
    return null;
  }

  // MVCArray인지 확인하고 적절한 길이와 접근 방법 사용
  const isPathMVCArray = path instanceof google.maps.MVCArray;
  const pathLength = isPathMVCArray ? path.getLength() : path.length;

  if (pathLength === 0) {
    return null;
  }

  let totalWeight = 0;
  let weightedLat = 0;
  let weightedLng = 0;

  for (let i = 0; i < pathLength - 1; i++) {
    // MVCArray와 일반 배열에 대한 접근 방식을 다르게 처리
    const p1 = isPathMVCArray ? path.getAt(i) : path[i];
    const p2 = isPathMVCArray ? path.getAt(i + 1) : path[i + 1];

    // LatLngLiteral을 LatLng로 변환
    const point1 =
      p1 instanceof google.maps.LatLng
        ? p1
        : new google.maps.LatLng(p1.lat, p1.lng);
    const point2 =
      p2 instanceof google.maps.LatLng
        ? p2
        : new google.maps.LatLng(p2.lat, p2.lng);

    // 선분의 중점 계산
    const segmentMidpoint = {
      lat: (point1.lat() + point2.lat()) / 2,
      lng: (point1.lng() + point2.lng()) / 2,
    };

    // 선분의 길이를 가중치로 계산
    const weight = google.maps.geometry.spherical.computeDistanceBetween(
      point1,
      point2
    );

    // 가중치를 적용한 합 계산
    weightedLat += segmentMidpoint.lat * weight;
    weightedLng += segmentMidpoint.lng * weight;
    totalWeight += weight;
  }

  // 가중 평균 계산
  return new google.maps.LatLng(
    weightedLat / totalWeight,
    weightedLng / totalWeight
  );
}

function getCombinedCenter(
  polylineOptions: google.maps.PolylineOptions[],
  markerPositions:
    | (google.maps.LatLng | google.maps.LatLngLiteral | null | undefined)[] = []
) {
  // 각 polyline의 중심점을 계산
  const polylineCenters = polylineOptions
    .map((options) => getPolylineCenter(options))
    .filter((center): center is google.maps.LatLng => center !== null);

  // 모든 중심점과 마커 위치를 합침
  const allPoints = [
    ...polylineCenters,
    ...markerPositions
      .map((pos) =>
        pos instanceof google.maps.LatLng
          ? pos
          : pos
          ? new google.maps.LatLng(pos.lat, pos.lng)
          : null
      )
      .filter((pos): pos is google.maps.LatLng => pos !== null),
  ];

  if (allPoints.length === 0) {
    return null;
  }

  const bounds = new google.maps.LatLngBounds();

  allPoints.forEach((point) => bounds.extend(point));

  return bounds.getCenter();
}

export { getPolylineCenter, getCombinedCenter };
