const OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION = [
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION.원산지_표기_미부착_및_오기_등_이슈가_발생하는_경우_추가_발생_비용은_실비청구되고_수입자가_부담합니다.",
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION.세관_무작위_검사_적발_시__관세사_수수료_50_000원_VAT_별도_가_추가_발생됩니다.___통관을_쉽다_파트너에게_의뢰_시_",
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION.선적된_화물의_실제_컨테이너_종류/수량_및_중량에_따라_견적이_변동될_수_있습니다.",
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION.위험물__2단적재_불가_등_특수한_경우_견적이_상이할_수_있고__선적이_불가할_수_있습니다.",
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION.물류비_대금정산은_국내_항구_도착_후__실제_선적된_컨테이너_종류/수량에_따라_청구됩니다.",
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION.터미널_FREETIME_및_STORAGE는_선사별_기본으로_제공되며__별도_추가를_원하시는_경우_담당자와_협의_부탁드립니다.",
];

const OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION = [
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION.실제_청구_시_환율은_On_Board_Date_기준__하나은행에서_최초_고시한_송금할_때의_환율로_적용됩니다.",
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION.20ft_컨테이너가_해당_선사_기준에_중량_초과시__OWS_Over_Weight_Surcharge_가_발생됩니다.",
  "constants:OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION.위험물_및_기타_특수한_화물의_경우_선적이_불가하거나_견적이_변동될_수_있습니다.",
];

const OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION = [
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION.원산지_표기_미부착_및_오기_등_이슈가_발생하는_경우_추가_발생_비용은_실비청구되고_수입자가_부담합니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION.세관_무작위_검사_적발_시__관세사_수수료_50_000원_VAT_별도_가_추가_발생됩니다.___통관을_쉽다_파트너에게_의뢰_시_",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION.선적된_화물의_실제_컨테이너_종류/수량_및_중량에_따라_견적이_변동될_수_있습니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION.위험물__2단적재_불가_등_특수한_경우_견적이_상이할_수_있고__선적이_불가할_수_있습니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION.다수_판매자_수출자_로부터_수입하는_경우__지정된_장소에서_화물을_모두_수취_후_선적됨이_원칙입니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION.물류비_대금정산은_국내_항구_도착_후__실제_선적된_부피/중량에_따라_청구됩니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION.터미널_FREETIME_및_STORAGE는_선사별_기본으로_제공되며__별도_추가를_원하시는_경우_담당자와_협의_부탁드립니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION.국내_창고보관료는_반입일_포함_7일_무료_주말_포함_입니다._이후_일_단위로_R.TON당_6_000원씩_보관료가_발생합니다._n예시__2R.TON_화물을_10일_보관_가정_시__무료기간_7일을_제외한_3일의_초과보관료가_청구_n_3일___2_R.TON___6_000원___36_000원_",
];

const OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION = [
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION.실제_청구_시_환율은_On_Board_Date_기준__하나은행에서_최초_고시한_송금할_때의_환율로_적용됩니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION.견적단위가_R.TON인_항목은__거래명세서_발행시_최종적으로_선적된_R.TON_값으로_적용되어_청구됩니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION.위험물__2단적재_불가__장축화물_등_특수한_경우_선적이_불가하거나_견적이_변동될_수_있습니다.",
  "constants:OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION.수출운송_시_1R.TON_미만의_화물은_최소_운임_기준에_따라_1R.TON_요율이_적용됩니다.",
];

const AIR_CAUTION_LIST_FOR_IMPORTATION = [
  "constants:AIR_CAUTION_LIST_FOR_IMPORTATION.원산지_표기_미부착_및_오기_등_이슈가_발생하는_경우_추가_발생_비용은_실비청구되고_수입자가_부담합니다.",
  "constants:AIR_CAUTION_LIST_FOR_IMPORTATION.세관_무작위_검사_적발_시__관세사_수수료_50_000원_VAT_별도_가_추가_발생됩니다.___통관을_쉽다_파트너에게_의뢰_시_",
  "constants:AIR_CAUTION_LIST_FOR_IMPORTATION.선적된_화물의_실제_부피/중량에_따라_견적이_변동될_수_있습니다.",
  "constants:AIR_CAUTION_LIST_FOR_IMPORTATION.위험물__2단적재_불가_등_특수한_경우_견적이_상이할_수_있고__선적이_불가할_수_있습니다.",
  "constants:AIR_CAUTION_LIST_FOR_IMPORTATION.물류비_대금정산은_국내_공항_도착_후__실제_선적된_부피/중량에_따라_청구됩니다.",
  "constants:AIR_CAUTION_LIST_FOR_IMPORTATION.국내_창고보관료는_반입되는_보세창고_종가/종량_기준에_따라_실비_청구됩니다.",
];

const AIR_CAUTION_LIST_FOR_EXPORTATION = [
  "constants:AIR_CAUTION_LIST_FOR_EXPORTATION.실제_청구_시_환율은_출항일_기준__하나은행에서_최초_고시한_송금할_때의_환율로_적용됩니다.",
  "constants:AIR_CAUTION_LIST_FOR_EXPORTATION.위험물__2단적재_불가__장축화물_등_특수한_경우_선적이_불가하거나_견적이_변동될_수_있습니다.",
  "constants:AIR_CAUTION_LIST_FOR_EXPORTATION.C.W는_항공사_보세창고_반입_시_실측되어_변경될_수_있고__이에_따라_견적이_변동될_수_있습니다.",
];

const OCEAN_TICKET_CAUTION_LIST = [
  "constants:OCEAN_TICKET_CAUTION_LIST.원산지_표기_미부착_및_오기_등_이슈가_발생하는_경우_추가_발생_비용은_실비청구되고_수입자가_부담합니다.",
  "constants:OCEAN_TICKET_CAUTION_LIST.세관_무작위_검사_적발_시__관세사_수수료_50_000원_VAT_별도_가_추가_발생됩니다.___통관을_쉽다_파트너에게_의뢰_시_",
  "constants:OCEAN_TICKET_CAUTION_LIST.선적된_화물의_실제_부피/중량에_따라_견적이_변동될_수_있습니다.",
  "constants:OCEAN_TICKET_CAUTION_LIST.위험물__2단적재_불가_등_특수한_경우_견적이_상이할_수_있고__선적이_불가할_수_있습니다.",
  "constants:OCEAN_TICKET_CAUTION_LIST.다수_판매자_수출자_로부터_수입하는_경우__지정된_장소에서_화물을_모두_수취_후_선적됨이_원칙입니다.",
  "constants:OCEAN_TICKET_CAUTION_LIST.물류비_대금정산은_국내_항구_도착_후__실제_선적된_부피/중량에_따라_청구됩니다.",
  "constants:OCEAN_TICKET_CAUTION_LIST.국내_창고보관료는_반입일_포함_7일_무료_주말_포함_입니다._이후_일_단위로_R.TON당_6_000원씩_보관료가_발생합니다._n예시__2R.TON_화물을_10일_보관_가정_시__무료기간_7일을_제외한_3일의_초과보관료가_청구_n_3일___2_R.TON___6_000원___36_000원_",
];

const CONSOL_CAUTION_LIST = [
  "constants:CONSOL_CAUTION_LIST.원산지_표기_미부착_및_오기_등_이슈가_발생하는_경우_추가_발생_비용은_실비청구되고_수입자가_부담합니다.",
  "constants:CONSOL_CAUTION_LIST.세관_무작위_검사_적발_시__관세사_수수료_50_000원_VAT_별도_가_추가_발생됩니다.___통관을_쉽다_파트너에게_의뢰_시_",
  "constants:CONSOL_CAUTION_LIST.선적된_화물의_실제_부피/중량에_따라_견적이_변동될_수_있습니다.",
  "constants:CONSOL_CAUTION_LIST.사전_동의없이__마이페이지_에_기재된_창고로_화물을_입고시킬_경우_거절할_수_있고_쉽다는_이에_책임지지_않습니다.",
  "constants:CONSOL_CAUTION_LIST.위험물__2단적재_불가_등_특수한_경우_견적이_상이할_수_있고__선적이_불가할_수_있습니다.",
  "constants:CONSOL_CAUTION_LIST.다수_판매자_수출자_로부터_수입하는_경우__지정된_장소에서_화물을_모두_수취_후_선적됨이_원칙입니다.",
  "constants:CONSOL_CAUTION_LIST.소상품_신고로_수출통관이_진행됩니다._수출자_명의로_통관을_희망하실_경우_통관비가_청구됩니다.",
  "constants:CONSOL_CAUTION_LIST.소상품_신고로_수출통관이_진행되는_경우__한국_수입통관_후_환불_등_사유로_인한_SHIPBACK은_불가하고_이슈_발생에_대한_책임을_지지_않습니다.",
  "constants:CONSOL_CAUTION_LIST.물류비_대금정산은_국내_항구_도착_후__실제_선적된_부피/중량에_따라_청구됩니다.",
  "constants:CONSOL_CAUTION_LIST.국내_창고보관료는_7일_무료입니다._초과_시_보세창고_종가/종량_기준에_따라_실비_청구됩니다.",
];

export {
  AIR_CAUTION_LIST_FOR_EXPORTATION,
  AIR_CAUTION_LIST_FOR_IMPORTATION,
  CONSOL_CAUTION_LIST,
  OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION,
  OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION,
  OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION,
  OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION,
  OCEAN_TICKET_CAUTION_LIST,
};
